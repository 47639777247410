import React, { Component } from 'react'
import Banner from '../../utils/Banner'
import { logo2 } from '../../config';

export default class AboutUs extends Component {
    render() {
        return (
            <section class="text-gray-600  bg body-font">
                <Banner title='About Us' details='Meet the team behind the sceens' />

                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                            Meet Our Founder and CEO
                            </h1>
                        <p class="mb-8 leading-relaxed">
                            Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant
                            cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag
                            selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.
                                <br />
                            <br />
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid eaque delectus quasi nobis quis pariatur provident amet, excepturi
                                 numquam nulla hic inventore reprehenderit nesciunt neque reiciendis sunt quas quos tenetur?
                            </p>
                        {/* <div class="flex justify-center">
                                <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
                                <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
                            </div> */}
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src={logo2} />
                    </div>
                </div>
            </section>
        )
    }
}
