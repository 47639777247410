
import React, { Component } from 'react'
import { logo1 } from '../config'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'


export default function AppBar() {


    let match = useLocation();

    let menus = [
        {
            title: "Home",
            link: '/'
        },
        {
            title: "Show Room",
            link: '/show-room',
        },
        {
            title: "About Us",
            link: '/about-us'
        },
        {
            title: "Contact Us",
            link: '/contact-us'
        }
    ]
    console.log(match);
    return (

        <header className="text-gray-600 body-font">
            <div className="container mx-auto flex flex-wrap p-5 pb-0 flex-col md:flex-row items-center">
                <Link to='/' className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                    <img className='w-10 h-10' src={logo1} alt="Rh Automobile logo" />
                    <span className="ml-3 text-xl">RH Automobiles</span>
                </Link>
                <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                    {
                        menus.map(e => (
                            <Link key={e.path} className={`py-2 px-6 flex text-indigo-500 border-b-2 transition-all ${match.pathname === e.link ? 'border-indigo-500' : ''} `} to={e.link} >{e.title}</Link>
                        ))
                    }
                </nav>
            </div>
        </header>
    );

}