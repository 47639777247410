import React from 'react'
import { useForm } from 'react-hook-form';

export default function Login() {

    const { register, handleSubmit, errors, } = useForm();

    let submit = (data) => {
        console.log(data);
    }

    // const email = {

    // }
    return (
        <div className='container mx-auto ' >
            <form onSubmit={handleSubmit(submit)}>

                <div className='flex flex-col py-10 px-10'>

                    <div className='w-full lg:w-1/3 md:w-1/2 mx-auto'>
                        <div className='mb-5'>
                            <h1 className='text-4xl text-center'>Login</h1>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input ref={register({ required: true, })} type="email" id="name" name="name" className="w-full bg-white rounded border border-gray-300
                         focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none
                         text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out
                            
                         " />

                        </div>
                        <div className="mb-4">
                            <label ref={register({ required: true })} htmlFor="password" className="leading-7 text-sm text-gray-600">Password</label>
                            <input type="password" id="name" name="name" className="w-full bg-white rounded border border-gray-300
                         focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none
                         text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out
                            " />

                        </div>

                        <div className='mt-5'>
                            <button type='submit' className='py-2 px-10 uppercase bg-indigo-500 hover:bg-indigo-800 text-white rounded' >Login</button>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    )
}
