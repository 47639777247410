
import './App.css';
import Home from './pages/home/home';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import AppBar from './utils/appbar';
import Footer from './utils/Footer';
import AboutUs from './pages/about-us/about-us';
import ShowRoom from './pages/showRoom/showRoom';
import ContactUs from './pages/contact-us/ContactUs';
import Admin from './pages/admin/Admin';
import Login from './pages/admin/Login';

function App() {
  return (

    <BrowserRouter>
      <AppBar />

      <Switch>
        <Route exact={true} path="/">
          <Home />
        </Route>
        <Route path='/show-room'>
          <ShowRoom />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/contact-us">
          <ContactUs />
        </Route>
        <Route path='/admin'>
          <Admin />
        </Route>
        <Route path='/login'>
          <Login />
        </Route>

      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
