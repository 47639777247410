
import React from 'react'

export default function Admin() {
    return (
        <div>

        </div>
    )
}
