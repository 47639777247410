import React, { Component, Fragment } from 'react'
import Banner from '../../utils/Banner'

import img1 from '../../assets/img2.jpg';
import img2 from '../../assets/img3.jpg';
import img3 from '../../assets/img4.jpg';
import CarTile from '../home/carTile';
export default class ShowRoom extends Component {
    render() {

        let cars = [
            {

                title: '2017 Mitsubishi Pajero',
                brand: 'Mitsubishi',
                year: '2017',
                img: img1,
                price: 2000
            }, {

                title: '2017 Mitsubishi Pajero',
                brand: 'Mitsubishi',
                year: '2017',
                img: img2,
                price: 2000
            }, {

                title: '2017 Mitsubishi Pajero',
                brand: 'Mitsubishi',
                year: '2017',
                img: img3,
                price: 2000
            }, {

                title: '2017 Mitsubishi Pajero',
                brand: 'Mitsubishi',
                year: '2017',
                img: img1,
                price: 2000
            },
        ]

        return (
            <Fragment className='' >
                <Banner title='Show Room' details='shop with us' />
                <div className='container mx-auto pb-20' >
                    <div className="mt-10 flex flex-wrap -m-4  justify-items-center">

                        {cars.map(e => <CarTile key={e} {...e} />)}
                    </div>
                </div>
            </Fragment>
        )
    }
}
