
import React, { Component } from 'react'

export class CarTile extends Component {


    render() {

        let img = this.props.img;
        let title = this.props.title;
        let brand = this.props.brand;
        let year = this.props.year;
        let price = this.props.price;

        return (
            // 191828
            <div class="p-4 w-11/12 md:w-5/12 lg:w-4/12 mx-auto">
                <div class="h-full border-2 border-gray-100 rounded-lg overflow-hidden">
                    <img class=" h-56 w-full object-cover object-center" src={img} alt="blog" />
                    <div class="p-6">
                        <h2 class="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">{brand}</h2>
                        <h1 class="title-font text-lg font-medium   mb-3">{title}</h1>
                        <div class="flex border-t  border-gray-200 py-3">
                            <span className='text-gray-500' >Price </span>
                            <span class="ml-auto text-gray-900">${price}</span>
                        </div>

                        <div class="flex border-t  border-gray-200 py-3">
                            <span className='text-gray-500' >Year </span>
                            <span class="ml-auto text-gray-900">{year}</span>
                        </div>
                        <div class="flex items-center flex-wrap mt- ml-auto justify-end ">
                            <a href="tel:+2330000000000" class="bg-indigo-500 text-white hover:bg-indigo-800
                             inline-flex mt-2 md:mb-2 lg:mb-0 transition-colors
                              rounded px-5 py-2 ">
                                Reserve
                             </a>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CarTile
