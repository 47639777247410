
import React from 'react'

export default function Banner(props) {

    let title = props.title;
    let details = props.details;

    return (
        <div className='bg-gray-100  py-20 text-center' >
            <p className="text-xs text-indigo-600 font-semibold tracking-wide uppercase ">Rh Automobile</p>
            <p className="mt-3 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                {title}
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                {details}
            </p>
        </div>
    )
}
