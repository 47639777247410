import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import mainBanner from '../../assets/img1.jpg';
import img1 from '../../assets/img2.jpg';
import img2 from '../../assets/img3.jpg';
import img3 from '../../assets/img4.jpg';
import CarTile from './carTile';
import './home.css';
import ServiceTile from './serviceTile';
import Testimonial from './testimonial';
import { logo2 } from '../../config';

export default class Home extends Component {

    render() {
        return (
            <Fragment>

                <div className="relative bg-white overflow-hidden">
                    <div className="max-w-7xl mx-auto">
                        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                            <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                                <polygon points="50,0 100,0 50,100 0,100" />
                            </svg>

                            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                                <div className="sm:text-center lg:text-left">
                                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                        <span className="block text-indigo-600 xl:inline">RH </span>
                                        <span className="block xl:inline">Automobiles</span>
                                    </h1>
                                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae repellendus alias natus adipisci ipsam ex beatae nobis, commodi cupiditate quod.
                                 </p>
                                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">

                                        <Link to="/" className="  shadow px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-3 md:text-lg md:px-10">
                                            Show Room
                                        </Link>


                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={mainBanner} alt="" />
                    </div>
                </div>

                <section className="text-gray-600 bg-gray-100 body-font">
                    <div className="container px-5  py-20 mx-auto">
                        <div className="text-center mb-10">
                            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-4"> We offer the follow services</h1>
                            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>
                            <div className="flex mt-6 justify-center">
                                <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
                            </div>
                        </div>

                        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                            <ServiceTile title={"Buying Cars"} />
                            <ServiceTile title={"Shipping Cars"} />
                            <ServiceTile title={"Paper Works"} />
                            {/* <ServiceTile /> */}


                        </div>
                        {/* <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button> */}
                    </div>
                </section>


                <section>
                    <div className="container px-5 pt-24 py-10 mx-auto">
                        <div className="text-center">
                            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Rh Gallery</h2>
                            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Our Latest Orders
                             </p>
                            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                All cars are imported from USA. On each sales page you can see if the car is located in USA or what is the estimated date of arrival.
                                Please note that we sell cars that are taxed and registered, but also Tax-Free cars.
                              </p>
                        </div>


                        <div className="mt-10 flex flex-wrap -m-4  justify-items-center">
                            <CarTile img={img1} title='2017 Mitsubishi Pajero' brand='Mitsubishi' />
                            <CarTile img={img2} title='2017 Mitsubishi Pajero' brand='Mitsubishi' />
                            <CarTile img={img3} title='2017 Mitsubishi Pajero' brand='Mitsubishi' />
                            <CarTile img={img1} title='2017 Mitsubishi Pajero' brand='Mitsubishi' />
                            <CarTile img={img2} title='2017 Mitsubishi Pajero' brand='Mitsubishi' />
                            <CarTile img={img3} title='2017 Mitsubishi Pajero' brand='Mitsubishi' />
                        </div>


                        <div className="pt-10 text-center  ">
                            <Link to='/show-room' className="w-40 text-center flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                Find More
                                </Link>
                        </div>

                    </div>
                </section>


                <section class="text-gray-600 bg-gray-100 bg body-font">
                    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                        <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                            <h1 class="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-4 ">
                                Meet Our Founder and CEO
                            </h1>
                            <p class="mb-8 leading-relaxed">
                                Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant
                                cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag
                                selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.
                                <br />
                                <br />
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid eaque delectus quasi nobis quis pariatur provident amet, excepturi
                                 numquam nulla hic inventore reprehenderit nesciunt neque reiciendis sunt quas quos tenetur?
                                 </p>
                            {/* <div class="flex justify-center">
                                <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
                                <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
                            </div> */}
                        </div>
                        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                            <img class="object-cover object-center rounded" alt="hero" src={logo2} />
                        </div>
                    </div>
                </section>

                <section class="text-gray-600 body-font">
                    <div class="container px-5 py-24 mx-auto">
                        <h1 class="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-10 text-center">Testimonials</h1>
                        <div class="flex flex-wrap -m-4 overflow-x-auto">

                            <Testimonial />
                            <Testimonial />

                        </div>
                    </div>
                </section>

                <section class="text-gray-600 body-font bg-gray-100">
                    <div class="container px-5 py-24 mx-auto">
                        <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                            <h1 class="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
                                Looking for your next ride, let's help you out
                            </h1>
                            <Link to='/contact-us' class="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0  sm:ml-auto">Contact Us</Link>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}